import React from 'react';
import { Content } from '@layout';
import ProjectBox from './ProjectBox';
import Graph from './Graph';
import styles from './ProjectSection.module.scss';

export default function ProjectSection() {
  return (
    <div className={styles.wrapper}>
      <Content className={styles.content}>
        <div className={styles.text}>
          <div className={styles.title}>
            <h2 className={styles.shadow}>
              <span>
                Identify blockers.
                <br />
                Find solutions.
              </span>
            </h2>
          </div>
          <div className={styles.shadow}>
            <p>
              We use artificial intelligence to discover exactly what is holding your team back, and
              automatically recommend steps to alleviate those issues.
            </p>
          </div>
        </div>
        <ProjectBox />
      </Content>
      <Graph />
    </div>
  );
}
