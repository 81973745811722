import React from 'react';
import styles from './Hexagon.module.scss';
import classNames from 'classnames';

export default function Hexagon({
  children,
  direction = 'clockwise',
  size = 140,
  strokeWidth = 3,
  className
}) {
  const animationDirection = direction === 'clockwise' ? 'normal' : 'reverse';
  
  return (
    <div
      className={classNames(styles.wrapper, className)}
      style={{ width: size, height: size }}
    >
      {children}
      <svg
        viewBox="0 0 130 130"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={styles.hexagon}
        preserveAspectRatio="none"
      >
        <path
          style={{ animationDirection, strokeWidth }}
          d="M55.2151 5.7735C61.4031 2.20085 69.0271 2.20085 75.2151 5.7735L111.507 26.7265C117.695 30.2992 121.507 36.9017 121.507 44.047V85.953C121.507 93.0983 117.695 99.7008 111.507 103.274L75.2151 124.226C69.0271 127.799 61.4031 127.799 55.2151 124.226L18.9234 103.274C12.7354 99.7008 8.92344 93.0983 8.92344 85.953V44.047C8.92344 36.9017 12.7354 30.2992 18.9234 26.7265L55.2151 5.7735Z"
        />
      </svg>
    </div>
  );
}