import React from 'react';
import styles from './ProjectSection.module.scss';

export default function Graph() {
  return (
    <svg width="100%" height="100%" className={styles.graph} xmlns="http://www.w3.org/2000/svg">
      <defs>
        {/* <pattern id="smallGrid" width="8" height="8" patternUnits="userSpaceOnUse">
          <path d="M 8 0 L 0 0 0 8" fill="none" stroke="#dedede" strokeWidth="0.5" />
        </pattern> */}
        <pattern id="project-grid" width="80" height="80" patternUnits="userSpaceOnUse">
          <rect width="80" height="80" fill="url(#smallGrid)" />
          <path
            d="M 80 0 L 0 0 0 80"
            fill="none"
            stroke="#E1E1E1"
            strokeWidth="1.5"
            strokeDasharray="4"
          />
        </pattern>
      </defs>

      <rect width="100%" height="100%" fill="url(#project-grid)" />
    </svg>
  );
}
