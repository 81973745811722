import React, { useState } from 'react';
import Layout from '@layout';
import SEO from '@components/SEO';
import Hero from './Hero';
import PurpleSection from './PurpleSection';
import ProjectSection from './ProjectSection';

export default function HomePage() {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (
    <Layout onModalChange={setModalIsOpen}>
      <SEO />
      <Hero modalIsOpen={modalIsOpen} />
      <PurpleSection />
      <ProjectSection />
    </Layout>
  );
}
