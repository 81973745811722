import React, { useState, useEffect } from 'react';
import Hexagon from './Hexagon';
import Line from './Line';
import styles from './PurpleSection.module.scss';
import Email from './icons/email.inline.svg';
import Chat from './icons/chat.inline.svg';
import Lightbulb from './icons/lightbulb.inline.svg';
import { useInView } from 'react-intersection-observer';
import classNames from 'classnames';

export default function HexagonAnimation() {
  const [isVisible, setIsVisible] = useState(false);
  const [ref, inView] = useInView({
    threshold: .65
  });

  useEffect(() => {
    if (inView) {
      setIsVisible(true);
    }
  }, [inView]);

  return (
    <div
      ref={ref}
      className={classNames(styles.iconWrapper, {
        [styles.visible]: isVisible
      })}
    >
      <Hexagon
        className={classNames(styles.hex, styles.hex1)}
      >
        <Email />
      </Hexagon>
      <Line
        className={classNames(styles.line, styles.line1)}
      />
      <Hexagon
        size={180}
        strokeWidth={3.5}
        className={classNames(styles.hex, styles.hex3)}
      >
        <Lightbulb />
      </Hexagon>
      <Line
        className={classNames(styles.line, styles.line2)}
        direction="left"
      />
      <Hexagon
        direction="counterclockwise"
        className={classNames(styles.hex, styles.hex2)}
      >
        <Chat />
      </Hexagon>
    </div>
  );
}